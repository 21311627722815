import { configureStore, createSlice } from '@reduxjs/toolkit';

/* Fornisce un subarray di elementi scelti casualmente da un array più grande, con dimensione a scelta */
const getRandomSubarray = (arr, size) => {
    var shuffled = arr.slice(0), i = arr.length, temp, index;
    while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
}

/* Permette di selezionare un subarray di domande basato su un array di numeri di domanda scelti  */
const getQuestionsWithNumbers = (questions_array,numbers_sequence) => {
  let subset = []
  for(let s of numbers_sequence){
    for(let q of questions_array){
      if(s===q.number){
        subset.push(q)
      }
    }
  }
  return subset
}


const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    questions: {},
    tot_questions: 0,
    min_score_to_pass: 0,
    video_watched: false
  },
  reducers: {
    selectAnswerGlobal: (state,action) => {
        const selected_letter = action.payload.letter
        const question_number = action.payload.number
        if(selected_letter){
          state.questions.it[question_number-1].selected_letter=selected_letter
          state.questions.it[question_number-1].confirmed=true
          state.questions.en[question_number-1].selected_letter=selected_letter
          state.questions.en[question_number-1].confirmed=true
          state.questions.de[question_number-1].selected_letter=selected_letter
          state.questions.de[question_number-1].confirmed=true
        }
    },
    unselectAnswerGlobal: (state,action) => {
      const question_number = action.payload.number
        state.questions.it[question_number-2].confirmed=false
        state.questions.en[question_number-2].confirmed=false
        state.questions.de[question_number-2].confirmed=false
    },
    resetTest: (state) => {
      for(let q of state.questions.it){
        state.questions.it[q.number-1].confirmed=false
        state.questions.it[q.number-1].selected_letter=null
        state.questions.en[q.number-1].confirmed=false
        state.questions.en[q.number-1].selected_letter=null
        state.questions.de[q.number-1].confirmed=false
        state.questions.de[q.number-1].selected_letter=null
      }
    },
    setQuestions: (state,action) => {
      const inputquestions_it = action.payload.questions.it
      const inputquestions_en = action.payload.questions.en
      const inputquestions_de = action.payload.questions.de

      const questions_arr_it = Object.values(inputquestions_it)
      const questions_arr_en = Object.values(inputquestions_en)
      const questions_arr_de = Object.values(inputquestions_de)

      let all_sequence=[]
      let sub_sequence=[]
      let seveso_found=0
      for(let q of questions_arr_it){
        /* If a question about seveso is found, always make it the first question, the others random
           else, all questions random */
        if((q.text).toLowerCase().includes('seveso')){
          seveso_found=q.number
          sub_sequence.push(seveso_found)
        } else {
          all_sequence.push(q.number)
        }
      }
      if(seveso_found){
        sub_sequence = sub_sequence.concat(getRandomSubarray(all_sequence,(action.payload.tot_question_number-1)))
      } else {
        sub_sequence = sub_sequence.concat(getRandomSubarray(all_sequence,action.payload.tot_question_number))
      }
      let subset_it = getQuestionsWithNumbers(questions_arr_it,sub_sequence)
      let subset_en = getQuestionsWithNumbers(questions_arr_en,sub_sequence)
      let subset_de = getQuestionsWithNumbers(questions_arr_de,sub_sequence)

      let sbst_it = subset_it.map((q,idx) => ({...q,number: idx+1, selected_letter: null, confirmed: false}))
      let sbst_en = subset_en.map((q,idx) => ({...q,number: idx+1, selected_letter: null, confirmed: false}))
      let sbst_de = subset_de.map((q,idx) => ({...q,number: idx+1, selected_letter: null, confirmed: false}))

      state.questions = {it: sbst_it,en: sbst_en,de: sbst_de}
      state.min_score_to_pass = action.payload.pass_score
      state.tot_questions = action.payload.tot_question_number
      
      const serializedQuestions_it=JSON.stringify(sbst_it)
      const serializedQuestions_en=JSON.stringify(sbst_en)
      const serializedQuestions_de=JSON.stringify(sbst_de)

      localStorage.setItem('questions_it',serializedQuestions_it)
      localStorage.setItem('questions_en',serializedQuestions_en)
      localStorage.setItem('questions_de',serializedQuestions_de)
      localStorage.setItem('tot_questions',state.tot_questions)
      localStorage.setItem('min_score_to_pass',state.min_score_to_pass)
  },
  getQuestionsFromLocalStorage: (state) => {
    const sbst_it=JSON.parse(localStorage.getItem('questions_it'))
    const sbst_en=JSON.parse(localStorage.getItem('questions_en'))
    const sbst_de=JSON.parse(localStorage.getItem('questions_de'))
    state.questions = {it: sbst_it,en: sbst_en,de: sbst_de}
    state.tot_questions=localStorage.getItem('tot_questions')
    state.min_score_to_pass=localStorage.getItem('min_score_to_pass')
  },
  saveQuestionsInLocalStorage: (state) => {
    localStorage.setItem('questions_it',JSON.stringify(state.questions.it))
    localStorage.setItem('questions_en',JSON.stringify(state.questions.en))
    localStorage.setItem('questions_de',JSON.stringify(state.questions.de))
    localStorage.setItem('tot_questions',state.tot_questions)
    localStorage.setItem('min_score_to_pass',state.min_score_to_pass)
  },
  setVideoWatched: (state,action) => {
    state.video_watched = action.payload
    localStorage.setItem('video_watched',state.video_watched?'true':'false')
  },
  loadVideoStatusFromLocalStorage: (state) => {
    state.video_watched=localStorage.getItem('video_watched')==='true'?true:false
  },
}})

const userSlice = createSlice({
  name: 'user',
  initialState: {
      logged_in: false,
      wrong_login: false,
      hash: '',
      exam_passed: false,
      access_type: 0,
      score: 0,
      exam_finished: 0
  },
  reducers: {
    setWrongLogin: (state,action) => {
      state.wrong_login = action.payload
      localStorage.setItem('wrong_login',state.wrong_login)
    },
    setScore: (state,action) => {
      state.score = action.payload
      localStorage.setItem('score',state.score)
    },
    setExamPassed: (state,action) => {
      state.exam_passed = action.payload
      localStorage.setItem('exam_passed',state.exam_passed?'true':'false')
    },
    setExamFinished: (state,action) => {
      state.exam_finished = action.payload
      localStorage.setItem('exam_finished',state.exam_finished?'true':'false')
    },
    logIn: (state,action) => {
      state.logged_in=true
      state.wrong_login=false
      state.hash=action.payload.hash
      state.badge_number=action.payload.badge_number?action.payload.badge_number:''
      state.exam_passed=action.payload.exam_passed
      state.score=action.payload.score?action.payload.score:0
      state.access_type=action.payload.access_type
    },
    saveInLocalStorage: state => {
      localStorage.setItem('logged_id',state.logged_in?'true':'false')
      localStorage.setItem('wrong_login',state.wrong_login?'true':'false')
      localStorage.setItem('hash',state.hash)
      localStorage.setItem('badge_number',state.badge_number?state.badge_number:'')
      localStorage.setItem('exam_passed',state.exam_passed?'true':'false')
      localStorage.setItem('score',state.score?state.score:0)
      localStorage.setItem('access_type',state.access_type)
    },
    loadFromLocalStorage: state => {
      state.logged_in=localStorage.getItem('logged_id')==='true'?true:false
      state.wrong_login=localStorage.getItem('wrong_login')==='true'?true:false
      state.hash=localStorage.getItem('hash')
      state.badge_number=localStorage.getItem('badge_number')?localStorage.getItem('badge_number'):''
      state.exam_passed=localStorage.getItem('exam_passed')==='true'?true:false
      state.access_type=localStorage.getItem('access_type')?localStorage.getItem('access_type'):0
      state.exam_finished=localStorage.getItem('exam_finished')==='true'?true:false
      state.score=parseInt(localStorage.getItem('score'))
    },
    logOut: state => {
      state.logged_in=false
      state.hash=''
      state.badge_number=''
      state.exam_passed=false
      state.access_type=0
      state.score=0
      state.exam_finished=false
      localStorage.setItem('logged_id','false')
      localStorage.setItem('hash','')
      localStorage.setItem('badge_number','')
      localStorage.setItem('exam_passed','false')
      localStorage.setItem('access_type',0)
      localStorage.setItem('score',0)
      localStorage.setItem('exam_finished','false')
    }
  }

},)


const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    questions: questionsSlice.reducer,
  },
});

export const {logIn,saveInLocalStorage,loadFromLocalStorage,logOut,setScore,setExamPassed,setExamFinished,setWrongLogin} = userSlice.actions
export const {setQuestions,setVideoWatched,loadVideoStatusFromLocalStorage,
  getQuestionsFromLocalStorage,selectAnswerGlobal,unselectAnswerGlobal,resetTest} = questionsSlice.actions

export default store;
